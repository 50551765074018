.container {
  width: 100%;
  display: flex;

  .content {
    width: 100%;

    .page {
      padding: 0 20px 20px;
      height: calc(100vh - 50px);
      background-color: #f4f5fa;
      overflow-y: scroll;
      min-width: 1000px;

      &--title {
        font-size: 18px;
        height: 50px;
        line-height: 50px;
        color: #cf1322;
      }

      &--content {
        padding: 20px;
        min-height: calc(100vh - 90px);
        background-color: #ffffff;
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .03);
        margin-top: 20px;
        border-radius: 4px;

        &-home {
          background-color: #f4f5fa;
          box-shadow: none;
          padding: 0;
        }
      }
    }
  }
}
