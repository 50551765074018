@import '~antd/dist/antd.css';

.color-primary {
  color: #1089ff;
}

.search {
  display: flex;
  margin-bottom: 15px;
  align-items: center;

  .search--block {
    width: 200px;
    margin-right: 15px;
  }
}

.menu {
  margin-bottom: 15px;
  background-color: #f5f6f9;
  padding: 8px 5px;

  button {
    margin-right: 10px;
  }
}

