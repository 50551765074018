.app-header {
  height: 50px;
  line-height: 50px;
  display: flex;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  padding-right: 25px;
  padding-left: 20px;
  justify-content: space-between;

  .page-title {
    padding-left: 10px;
    color: #505458;
    font-size: 16px;
    font-weight: 700;
  }

  .account {
    cursor: pointer;
  }
}
