.login {
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(-90deg, #29bdd9 0%, #276ace 100%);

  &-form {
    .title {
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 30px;
      color: #333333c9;
    }

    width: 400px;
    padding: 50px 50px 60px;
    background-color: #fff;
    margin: 200px auto 0;
    border-radius: 4px;
    box-shadow: 0 10px 40px -10px rgba(0, 64, 128, .2);

    & .captcha {
      height: 30px;
      cursor: pointer;
    }
  }

  & .footer {
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
    color: #f4f5fa94;
    text-align: center;

    & a {
      color: #f4f5fa94;
    }
  }
}
