.app-menu {
  height: 100vh;
  background-color: #343956;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.50);
  z-index: 999;

  &--collapsed {
    width: 200px;
  }

  .collapse {
    color: #999999;
    position: absolute;
    bottom: 20px;
    left: 32px;
    cursor: pointer;
  }

  .header {
    display: flex;
    text-align: center;
    height: 50px;
    line-height: 50px;
    color: rgb(160, 180, 200);
    font-size: 16px;
    font-weight: bold;
    background-color: #343956;
    transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s, font-size 0.3s linear;
    width: 100%;

    &.header-collapsed {
      font-size: 14px;
      width: 80px;
    }

    &-logo {
      margin-right: 10px;
      margin-left: 25px;
      width: 32px;

      img {
        width: 100%;
      }
    }
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected { // 展开选中的子菜单
    background-color: #637dfd;
    color: #ffffff;
    // border-left: 3px solid #3aa9f5;
  }

  .ant-menu .ant-menu-item::after {
    // border-left: 3px solid #3aa9f5;
    border: none;
  }

  .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: none;
  }

  .ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
    width: 100%;
  }

  .ant-menu-submenu > .ant-menu { // 展开未中的子菜单
    background-color: #242634;
    color: #bcbcc6;
    width: 100%;
  }

  .ant-menu-item:active, .ant-menu-submenu-title:active {
    background-color: #242634;
  }

  .ant-menu-submenu-selected, .ant-menu-item-selected {
    color: #ffffff;
  }

  .ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
    color: #ffffff;
  }
}

